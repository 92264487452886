import React, { useMemo, useState } from 'react';
import { ButtonToolbar, Spinner, useRouter } from '@tripledotstudios/react-core';

import APP_DATA from '@services/appData';
import {
  useQuery,
  useConfirm,
} from '@hooks';

import {
  PageHeader,
  Pagination,
  ReactTable,
  RulesList,
  DuplicationModalWithPriority,
  TIMESTAMP_COLUMNS,
  StatusAndAvailabilityColumn,
  PriorityColumn,
} from '@pages/common';
import { collectionResponseHandlerFactory } from '@requests/responseHandlers';
import { MachineLearningRoutes } from '@pages/routes';

import IconButton from '@controls/buttons';

import Filter from './Filter';

const columnsFactory = ({
  statuses, editPath, applicationId, onDelete, setDuplicatedRecord,
}) => ([
  { Header: 'ID', accessor: 'id' },
  { Header: 'Name', accessor: 'name' },
  PriorityColumn,
  StatusAndAvailabilityColumn({ statuses }),
  ...TIMESTAMP_COLUMNS,
  {
    Header: 'Rules',
    Cell: ({ row: { original: { ruleSectionsAttributes } } }) => <RulesList rules={ruleSectionsAttributes} />,
  },
  {
    Header: 'Actions',
    Cell: ({ row: { original: { id, name, readOnly } } }) => (
      <ButtonToolbar>
        <IconButton.Edit to={editPath({ id, applicationId })} minimized />
        <IconButton.Duplicate onClick={() => setDuplicatedRecord({ id, name })} minimized />
        <IconButton.Delete onClick={onDelete(id, name)} disabled={readOnly} minimized />
      </ButtonToolbar>
    ),
  },
]);

export default function MachineLearningModelsIndex() {
  const {
    indexRequest, deleteRequest, duplicateRequest, newPath, editPath,
  } = MachineLearningRoutes.Models;
  const [duplicatedRecord, setDuplicatedRecord] = useState(null);
  const { response, refetch, isLoading } = useQuery(indexRequest);
  const confirm = useConfirm();
  const { query: { applicationId } } = useRouter();

  const { enums } = APP_DATA;
  const statuses = enums['MachineLearning::ModelStatuses'];

  const responseHandler = collectionResponseHandlerFactory({
    entityName: 'ML Model',
    actionName: 'deletion',
    refetch,
  });

  const onDelete = (id, name) => () => {
    confirm.showConfirmation({
      title: `ML Model '${name}' will be deleted and not available for work. Continue?`,
    })
      .then(() => deleteRequest({ id, applicationId })
        .then(responseHandler));
  };

  const columns = useMemo(() => columnsFactory({
    statuses, editPath, applicationId, onDelete, setDuplicatedRecord,
  }), []);

  return (
    <>
      <PageHeader
        title="ML Models"
        filter={<Filter statusesEnum="MachineLearning::ModelStatuses" />}
      >
        <IconButton.New to={newPath({ applicationId })} />
      </PageHeader>

      {isLoading ? <Spinner /> : (
        response && (
          <>
            <ReactTable
              columns={columns}
              data={response.items}
              defaultSort={{ id: 'priority', desc: true }}
            />
            <DuplicationModalWithPriority
              record={duplicatedRecord}
              entityName="ML Model"
              handleClose={() => setDuplicatedRecord(null)}
              onSubmit={(values) => duplicateRequest({ ...values, applicationId })}
              entitiesByPriority={response.entitiesByPriority || {}}
            />
            <Pagination
              pageCount={response._meta.pages}
            />
          </>
        )
      )}
    </>
  );
}
